<template>
  <b-container fluid class="breadcrumb-panel">
    <b-breadcrumb class="text-align">
      <b-breadcrumb-item href="/">Home</b-breadcrumb-item>
      <template v-if="genreLink">
        <b-breadcrumb-item @click="goToGame(genreLink)" :active="activeLink">{{
          genre
        }}</b-breadcrumb-item>
      </template>
      <template v-if="game">
        <b-breadcrumb-item active>{{
          game
        }}</b-breadcrumb-item>
      </template>
    </b-breadcrumb>
  </b-container>
</template>

<script>
export default {
  name: "BreadcrumbComponent",
  data() {
    return {};
  },
  props: {
    genreLink: String,
    gameLink: String,
    genre: String,
    game: String,
    activeLink: Boolean
  },
  methods: {
    goToGame(genreLink) {
      if (!genreLink) {
        return;
      }
      this.$router.push(`/games/${genreLink}`);
    },
  },
};
</script>
<style lang="scss" src="./scss/styles.scss"/>
