<template>
  <div class="game-card-panel">
    <div class="card-align">
      <b-card :title="title" img-top tag="article" class="mb-2">
        <div class="iframe-container">
          <youtube
            :video-id="youtubeVideoId"
            :player-vars="playerVars"
            @playing="playing"
          />
          <b-card-text class="text-align">
            {{ cardText }}
          </b-card-text>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "GameCardComponent",
  props: {
    title: String,
    cardText: String,
    gameId: Number,
    youtubeVideoId: String,
  },
  data() {
    return {
      playerVars: {
        autoplay: 0,
        fitParent: true,
      },
    };
  },
};
</script>
<style lang="scss" src="./scss/styles.scss" />
