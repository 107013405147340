import Vue from "vue";
import Router from "vue-router";
import HomeView from "../views/HomeView/HomeView";
import GamesView from "../views/GamesView/GamesView";
import GameView from "../views/GameView/GameView";
import GamesSearchView from "../views/GamesSearchView/GamesSearchView";

Vue.use(Router);

const routes = [
  {
    name: "HomeView",
    path: "/",
    component: HomeView,
    meta: { title: "Videogame-trailers.com" },
  },
  {
    name: "GamesList",
    path: "/games/:id",
    component: GamesView,
    meta: { title: "Videogame-trailers.com" },
    children: [
      {
        path: "",
        component: GamesView,
      },
    ],
  },
  {
    name: "GameDetailed",
    path: "/game/:id",
    component: GameView,
    children: [
      {
        path: "",
        component: GameView,
      },
    ],
  },
  {
    name: "GamesSearch",
    path: "/games-search",
    component: GamesSearchView,
    children: [
      {
        path: "",
        component: GamesSearchView,
      },
    ],
  },
];

const router = new Router({
  routes,
  mode: "history",
  scrollBehavior(from, to, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      this.app.$el.scrollTo(0, 0);
    }
  },
});

export default router;
