import { render, staticRenderFns } from "./GameView.vue?vue&type=template&id=412f755c"
import script from "./GameView.vue?vue&type=script&lang=js"
export * from "./GameView.vue?vue&type=script&lang=js"
import style0 from "./scss/styles.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports