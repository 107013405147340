import { endpoints } from "../../../endpoints/endpoints.js";

const videoGameModule = {
  namespaced: true,
  state: {
    videoGames: [],
    videoGame: [],
    videoGamesSearch: [],
    currentCat: "action",
    videoGamesSearchText: "",
    apiResponseMessage: "",
    apiResponseStatus: 0,
  },
  actions: {
    async fetchGames({ commit }) {
      // const gamesEndPoint = "/games.json";
      const response = await fetch(endpoints.games);
      const result = await response.json();
      commit("setGames", result);
    },
    async fetchGame({ commit }) {
      const gameEndPoint = "/game.json";
      const response = await fetch(gameEndPoint);
      const result = await response.json();
      commit("setGame", result.game);
    },
    async fetchGamesByCat({ commit }, { cat, page }) {
      const response = await fetch(`${endpoints.games}/${cat}?page=${page}`);
      const result = await response.json();
      commit("setGames", result);
      commit("setCat", result.genre);
      return result;
    },
    async fetchGameById({ commit }, url) {
      const response = await fetch(`${endpoints.game}/${url}`);
      const result = await response.json();
      commit("setGame", result);
      return result;
    },
    async fetchGamesFirstGenre({ commit }, page) {
      const response = await fetch(`${endpoints.gamesFirstGenre}?page=${page}`);
      const result = await response.json();
      commit("setGames", result);
      return result;
    },
    async fetchGamesSearch({ commit }, payload) {
      const response = await fetch(endpoints.gamesSearch, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      const result = await response.json();
      const responseStatus = response.status;
      commit("setVideoGameResponseMessage", result);
      commit("setVideoGameResponseStatus", responseStatus);
      commit("setGamesSearch", result);
      commit("setGamesSearchText", result.video_game_search);
    },
  },
  mutations: {
    setGames(state, games) {
      state.videoGames = games;
    },
    setGame(state, game) {
      state.videoGame = game;
    },
    setCat(state, cat) {
      state.currentCat = cat;
    },
    setGamesSearch(state, gamesSearch) {
      state.videoGamesSearch = gamesSearch;
    },
    setGamesSearchText(state, gamesSearchText) {
      state.videoGamesSearchText = gamesSearchText;
    },
    setVideoGameResponseMessage(state, apiResponseMessage) {
      state.apiResponseMessage = apiResponseMessage;
    },
    setVideoGameResponseStatus(state, apiResponseStatus) {
      state.apiResponseStatus = apiResponseStatus;
    },
  },
  getters: {
    getGames: (state) => state.videoGames,
    getGame: (state) => state.videoGame,
    getCat: (state) => state.currentCat,
    getGamesSearch: (state) => state.videoGamesSearch,
    getGamesSearchText: (state) => state.videoGamesSearchText,
    getVideoGameResponseMessage: (state) => state.apiResponseMessage,
    getVideoGameResponseStatus: (state) => state.apiResponseStatus,
  },
};

export default videoGameModule;
