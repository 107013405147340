<template>
  <b-container fluid class="home-panel">
    <b-row>
      <b-col class="col-md-12">
        <GamesSearchComponent />
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-md-12">
        <BreadcrumbComponent
          genreLink="action"
          gameLink=""
          genre="Action"
          game=""
          :activeLink="true"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-md-4" v-for="item in getGames" :key="item.id">
        <template v-if="item.id">
          <GameCardsListComponent
            :title="item.video_game_name"
            :image="item.youtube_url"
            :imageAlt="item.video_game_name"
            :gameId="item.id"
            :cardUrl="item.url"
          />
        </template>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <template v-if="getGames && getGames.pagination">
          <pagination
            v-model="page"
            :records="getGames.pagination.total"
            :per-page="getGames.pagination.per_page"
            :options="paginateOptions"
            @paginate="setVideoGamePages"
          />
        </template>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Pagination from "vue-pagination-2";
import GameCardsListComponent from "../../components/GameCardsListComponent/GameCardsListComponent";
import BreadcrumbComponent from "../../components/BreadcrumbComponent/BreadcrumbComponent";
import GamesSearchComponent from "../../components/GamesSearchComponent/GamesSearchComponent";
export default {
  name: "HomeView",
  components: {
    GameCardsListComponent,
    Pagination,
    BreadcrumbComponent,
    GamesSearchComponent,
  },
  data() {
    return {
      page: 1,
      paginateOptions: {
        chunk: 5,
      },
    };
  },
  methods: {
    ...mapActions("videoGameModule", ["fetchGamesFirstGenre"]),

    setVideoGamePages(page) {
      this.fetchGamesFirstGenre(page);
    },
  },
  computed: {
    ...mapGetters("videoGameModule", ["getGames"]),
  },
  created() {
    this.fetchGamesFirstGenre(this.page);
  },
};
</script>
<style lang="scss" src="./scss/styles.scss" />
