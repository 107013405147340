<template>
  <div id="app">
    <HeaderComponent />
    <b-container fluid>
      <b-row>
        <b-col md="3">
          <NavigationComponent />
        </b-col>
        <b-col md="9">
          <router-view />
        </b-col>
      </b-row>
    </b-container>
    <FooterComponent />
  </div>
</template>

<script>
import NavigationComponent from "./components/NavigationComponent/NavigationComponent";
import HeaderComponent from "./components/HeaderComponent/HeaderComponent";
import FooterComponent from "./components/FooterComponent/FooterComponent";
import { mapActions, mapMutations } from "vuex";

export default {
  name: "App",
  components: {
    NavigationComponent,
    HeaderComponent,
    FooterComponent,
  },
  data() {
    return {
      routeCat: "",
      routeGame: "",
    };
  },
  watch: {
    $route() {
      this.getCatFromUrl();
      this.getGameFromUrl();

      if (this.routeCat) {
        this.setCat(this.routeCat);
        this.getGamesByCat();
      }

      if (this.routeGame) {
        this.getGameById();
      }
    },
  },
  methods: {
    ...mapActions("videoGameModule", ["fetchGamesByCat"]),
    ...mapActions("videoGameModule", ["fetchGameById"]),
    ...mapMutations("videoGameModule", ["setCat"]),

    getGamesByCat() {
      this.fetchGamesByCat({ cat: this.routeCat, page: 1 });
    },
    getCatFromUrl() {
      this.routeCat = this.$route.path.split("/")[2];
    },
    getGameById() {
      this.fetchGameById(this.routeGame);
    },
    getGameFromUrl() {
      this.routeGame = this.$route.path.split("/")[2];
    },
  },
};
</script>
<style lang="scss" src="./scss/styles.scss" />
