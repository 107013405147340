<template>
  <b-container fluid class="home-panel">
    <b-row>
      <b-col class="col-md-12">
        <GamesSearchComponent />
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-md-4" v-for="item in getGamesSearch" :key="item.id">
        <template v-if="item.id">
          <GameCardsListComponent
            :title="item.video_game_name"
            :image="item.youtube_url"
            :imageAlt="item.video_game_name"
            :gameId="item.id"
            :cardUrl="item.url"
          />
        </template>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <template v-if="getGamesSearch && getGamesSearch.pagination">
          <pagination
            v-model="page"
            :records="getGamesSearch.pagination.total"
            :per-page="getGamesSearch.pagination.per_page"
            :options="paginateOptions"
            @paginate="setVideoGamePages"
          />
        </template>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Pagination from "vue-pagination-2";
import GameCardsListComponent from "../../components/GameCardsListComponent/GameCardsListComponent";
import GamesSearchComponent from "../../components/GamesSearchComponent/GamesSearchComponent";
export default {
  name: "GamesSearchView",
  components: {
    GameCardsListComponent,
    Pagination,
    GamesSearchComponent,
  },
  data() {
    return {
      page: 1,
      paginateOptions: {
        chunk: 5,
      },
    };
  },
  methods: {
    ...mapActions("videoGameModule", ["fetchGamesSearch"]),

    setVideoGamePages(page) {
      this.fetchGamesSearch({
        videoGame: this.getGamesSearchText,
        page: page
      });
    },
  },
  computed: {
    ...mapGetters("videoGameModule", ["getGamesSearch"]),
    ...mapGetters("videoGameModule", ["getGamesSearchText"]),
  },
  created() {
    this.fetchGamesSearch({
        videoGame: this.getGamesSearchText,
        page: this.page
      });
  },
};
</script>
<style lang="scss" src="./scss/styles.scss" />
