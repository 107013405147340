import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
//import localForage from 'localforage';
import videoGameModule from './modules/videoGameModule/videoGameModule';
import navigationModule from './modules/navigationModule/navigationModule';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    // storage: localForage,
    storage: window.localStorage,
    asyncStorage: false,
});

const store = new Vuex.Store({
    modules: {
        videoGameModule,
        navigationModule,
    },
    plugins: [vuexLocal.plugin]
});

export default store;