<template>
  <b-container fluid class="footer-panel">
    <b-row class="background-color">
      <b-col lg="6" class="font-color text-align-left copyright-position">
        &copy; Copyright 2024 | All rights reserved
      </b-col>
      <b-col lg="6" class="font-color text-align-right powered-position">
        Powered by:
        <a href="http://mm-webstudio.com" target="_blank" title="mm-webstudio"
          >mm-webstudio</a
        >
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>
<style lang="scss" src="./scss/styles.scss"/>
