<template>
  <b-form>
    <b-container fluid class="search-panel">
      <b-row class="padding-bottom">
        <b-col lg="10" class="padding-left">
          <b-form-input
            v-model="searchText"
            placeholder="Enter videogame"
            :state="isSearchStateValid"
          ></b-form-input>
          <b-form-invalid-feedback id="input-live-feedback">
            Enter at least 2 letters
          </b-form-invalid-feedback>
        </b-col>
        <b-col lg="2" class="padding-left">
          <b-button
            @click="submitForm(searchText)"
            class="button-text-color button-color"
            href="#"
            :disabled="isButtonDisabled"
            >Search</b-button
          >
        </b-col>
      </b-row>
    </b-container>
  </b-form>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

export default {
  name: "GamesSearchComponent",
  data() {
    return {
      searchText: "",
      url: this.$route.path.split("/")[1],
    };
  },
  methods: {
    ...mapActions("videoGameModule", ["fetchGamesSearch"]),
    ...mapMutations("videoGameModule", ["setGamesSearchText"]),

    isValid() {
      return this.searchText.length > 1 ? true : false;
    },

    submitForm() {
      this.setGamesSearchText(this.searchText);
      this.fetchGamesSearch({
        videoGame: this.searchText,
      });

      if (this.url !== "games-search") {
        this.$router.push("/games-search");
      }
    },
  },
  computed: {
    isSearchStateValid() {
      if (this.searchText) {
        return this.isValid(this.searchText);
      }
      return null;
    },

    isButtonDisabled() {
      return this.searchText.length > 1 ? false : true;
    },
  },
};
</script>
<style lang="scss" src="./scss/styles.scss" />
