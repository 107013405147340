import { endpoints } from "../../../endpoints/endpoints.js";

const navigationModule = {
  namespaced: true,
  state: {
    navigation: [],
  },
  actions: {
    async fetchNavigation({ commit }) {
      // const menuEndPoint = "/menu.json";
      const response = await fetch(endpoints.menu);
      const result = await response.json();
      commit("setNavigation", result);
    },
  },
  mutations: {
    setNavigation(state, navigation) {
      state.navigation = navigation;
    },
  },
  getters: {
    getNavigation: state => state.navigation,
  },
};

export default navigationModule;
