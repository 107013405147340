<template>
  <b-container fluid class="navigation-panel">
    <ul>
      <div v-for="item in getNavigation" :key="item.id">
        <li>
          <router-link :to="{ name: 'GamesList', params: { id: item.url } }">
            {{ item.name }}
          </router-link>
        </li>
      </div>
    </ul>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "NavigationComponent",
  data() {
    return {};
  },
  computed: {
    ...mapGetters("navigationModule", ["getNavigation"]),
  },
  methods: {
    ...mapActions("navigationModule", ["fetchNavigation"]),
  },
  created() {
    this.fetchNavigation();
  },
};
</script>
<style lang="scss" src="./scss/styles.scss" />
