<template>
  <b-container fluid class="game-view-panel">
    <b-row>
      <b-col class="col-md-12">
        <GamesSearchComponent />
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-md-12" v-for="item in getGame" :key="item.id">
        <BreadcrumbComponent
          :genreLink="item.video_game_genre_url"
          gameLink=""
          :genre="item.video_game_genre_name"
          :game="item.video_game_name"
          :activeLink="false"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-lg-12" v-for="item in getGame" :key="item.id">
        <GameCardComponent
          :title="item.video_game_name"
          :cardText="item.description"
          :gameId="item.id"
          :youtubeVideoId="item.youtube_url_id"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import GameCardComponent from "../../components/GameCardComponent/GameCardComponent";
import BreadcrumbComponent from "../../components/BreadcrumbComponent/BreadcrumbComponent";
import GamesSearchComponent from "../../components/GamesSearchComponent/GamesSearchComponent";
import { mapGetters } from "vuex";
export default {
  name: "GameView",
  components: { GameCardComponent, BreadcrumbComponent, GamesSearchComponent },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("videoGameModule", ["getGame"]),
  },
};
</script>
<style lang="scss" src="./scss/styles.scss" />
