<template>
  <div class="card-panel">
    <div class="card-align">
      <b-card
        :title="title"
        :img-src="image"
        :img-alt="imageAlt"
        img-top
        tag="article"
        class="mb-2"
      >
        <b-button
          @click="goToGame(cardUrl)"
          href="#"
          class="button-text-color button-color"
          >more information</b-button
        >
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "GameCardsListComponent",
  props: {
    title: String,
    image: String,
    imageAlt: String,
    gameId: Number,
    cardUrl: String,
  },
  data() {
    return {};
  },
  methods: {
    goToGame(cardUrl) {
      if (!cardUrl) {
        return;
      }
      this.$router.push(`/game/${cardUrl}`);
    },
  },
};
</script>
<style lang="scss" src="./scss/styles.scss"/>