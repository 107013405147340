<template>
  <b-container fluid class="header-panel">
    <b-row
      class="background-repeat bg-color"
      :style="{ backgroundImage: `url(${bgImage})` }"
    >
      <b-col lg="12">
        <b-link href="/">
          <img
            alt="videogame-trailers.com"
            src="../../assets/videogame_trailers_logo.png"
            class="logo responsive-logo"
            width="720"
            height="60"
          />
        </b-link>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "HeaderCompponent",
  data() {
    return {
      bgImage: "../images/wallpaper.jpg",
    };
  },
};
</script>
<style lang="scss" src="./scss/styles.scss"/>
